import $ from 'jquery';

$(document).ready(function() {
	$(document).on('click', 'a[href^="#"]', function (event) {
		event.preventDefault();
	
		$('html, body').animate({
			scrollTop: $($.attr(this, 'href')).offset().top -100
		}, 300);
	});

});