import $ from 'jquery';
import 'slick-carousel';

$(document).ready(function() {

	$('.slider_banner_hp').slick({
		arrows: false,
		dots: true,
		infinite: true,
		speed: 300
	});	
	
	$('.slider_partners').slick({
		autoplay: true,
		autoplaySpeed: 2000,
		arrows: false,
		dots: false,
		infinite: true,
		speed: 300,
		slidesToShow: 6,
		slidesToScroll: 3,
		  responsive: [
			{
			  breakpoint: 1024,
			  settings: {
				slidesToShow: 5,
				slidesToScroll: 5
			  }
			},
			{
			  breakpoint: 600,
			  settings: {
				slidesToShow: 2,
				slidesToScroll: 2
			  }
			},
			{
			  breakpoint: 480,
			  settings: {
				slidesToShow: 1,
				slidesToScroll: 1
			  }
			}
		  ]
	});	
	
});